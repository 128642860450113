<template>
  <div class="reviews-component">
    <h3>{{ reviews.length }} {{ $t("g.reviewsComponent/head") }}</h3>
    <review-component
      v-for="review in reviews"
      :key="review.id"
      :data="review"
    />
    <button
      class="btn"
      @click="toggleLimitPage"
      v-if="limit && data.length >= 2"
    >
      {{ $t("g.reviewsComponet/view_full_page") }}
    </button>
  </div>
</template>
<script>
import reviewComponent from "./reviewComponent";
export default {
  name: "reviewsComponent",
  props: ["data"],
  data() {
    return {
      limit: true,
    };
  },
  components: {
    reviewComponent,
  },
  computed: {
    reviews() {
      if (this.limit) {
        const reviews = this.data.slice(0, 2);
        return [...reviews];
      } else {
        return this.data;
      }
    },
  },
  methods: {
    toggleLimitPage() {
      this.limit = false;
    },
  },
};
</script>
<style lang="scss">
@import "./_index";
</style>
