<template>
  <div class="rate-component">
    <h3>{{ $t("g.rateComponent/head") }}</h3>
    <span class="d-flex">
      <p>{{ data.overall }}</p>
      <star-icon
        color="yellow"
        v-for="index in data.overall.toFixed(2)"
        :key="index"
      />
    </span>
    <p class="mt-n2">{{ componentHead }}</p>
    <b-form-checkbox-group
      v-model="filterSelect"
      class="mt-n1 group"
      @change="filtring"
    >
      <div
        v-if="data.rates"
        class="rate-row"
        v-for="(item, key) in data.reviews"
        :key="item.star"
      >
        <b-form-checkbox :value="key" />
        <span> {{ key }}.0</span>
        <span class="presenting">
          <star-icon :color="colorRates[key].star" />
          <progress-bar
            :color="colorRates[key].bar"
            :value="item.length"
            :total="data.count"
            class="w-50"
          />
          <span> ({{ item.length }})</span>
        </span>
      </div>
    </b-form-checkbox-group>
  </div>
</template>
<script>
import { BFormCheckbox, BFormCheckboxGroup } from "bootstrap-vue";
import starIcon from "@/components/productReviews/star";
import progressBar from "@/components/productReviews/progressBar";
export default {
  name: "rateComponent",
  props: ["data", "filters", "slug"],
  components: {
    starIcon,
    progressBar,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  data() {
    return {
      filterSelect: [],
      colorRates: {
        5: { bar: "green", star: "yellow" },
        4: { bar: "blue", star: "blue" },
        3: { bar: "orange", star: "orange" },
        2: { bar: "red", star: "red" },
        1: { bar: "red", star: "red" },
      },
      rate_data: null,
    };
  },
  created() {
    this.filterSelect = this.filters;
  },
  methods: {
    filtring() {
      this.$emit("handelFilter", this.filterSelect);
    },
    getRatePercentValue(rateIndex) {
      let ratePercent = null;
      switch (rateIndex) {
        case "1":
          ratePercent = this.data.rates.stars_one.percent;
          break;
        case "2":
          ratePercent = this.data.rates.stars_two.percent;
          break;
        case "3":
          ratePercent = this.data.rates.stars_three.percent;
          break;
        case "4":
          ratePercent = this.data.rates.stars_four.percent;
          break;
        case "5":
          ratePercent = this.data.rates.stars_five.percent;
          break;

        default:
          break;
      }

      return ratePercent;
    },
  },
  computed: {
    componentHead() {
      return this.$t("g.productReview/rateComponent/head").replace(
        ":reviews",
        this.data.count
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index";
</style>
