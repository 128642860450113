<template>
  <loading v-if="is_loading" />
  <div class="reviews-list" v-else>
    <h3>
      {{ $t("g.reviews_list/pagehead") }}
    </h3>
    <div class="holder" v-if="!thereIsReviews">
      <div class="col-lg-3 col-md-12">
        <rateComponent
          :data="reviewsData"
          @handelFilter="handelFilter"
          :filters="filters"
          :slug="$route.params.slug"
        />
      </div>
      <div class="reviews"><reviewsComponent :data="reviews" /></div>
    </div>
    <div class="no-review-div" v-else>
      {{ $t("g.reviews/no_reviews_for_product") }}
    </div>
  </div>
</template>

<script>
import loading from "@/components/shared/loading/loading.vue";
import rateComponent from "@/components/productReviews/rateComponent";
import reviewsComponent from "@/components/productReviews/reviewsComponent";
import { BCard } from "bootstrap-vue";
export default {
  created() {
    this.getData();
  },
  components: {
    BCard,
    rateComponent,
    reviewsComponent,
    loading,
  },
  data() {
    return {
      wholeData: [],
      reviewsData: {},
      filters: ["1", "2", "3", "4", "5"],
      is_loading: true,
      thereIsReviews: false,
    };
  },
  methods: {
    async getData() {
      if (this.productSlug) {
        try {
          const request = await this.$http.get(
            `vendor/products/${this.productSlug}/reviews`
          );
          if (request.status === 200 || request.status === 201) {
            this.wholeData = request.data;
            this.is_loading = false;
            if (this.wholeData.data.length > 0) {
              this.reviewsData = { ...this.handelReviews(this.wholeData) };
            } else {
              this.thereIsReviews = true;
            }
          } else {
            throw new Error();
          }
        } catch (error) {
          this.$helpers.handleError(error);
        } finally {
          this.is_loading = false;
        }
      }
    },
    handelReviews(reviews) {
      let reviewsArry = {
        5: [],
        4: [],
        3: [],
        2: [],
        1: [],
      };
      Array.isArray(reviews?.data)
        ? reviews?.data.map((review) => {
            reviewsArry[review.star].push(review);
          })
        : null;
      return {
        count: reviews?.rates.count,
        reviews: reviewsArry,
        rates: reviews?.rates,
        overall: reviews?.rates.overall,
      };
    },
    handelFilter(newData) {
      this.filters = newData;
      if (newData.length == 0) {
        this.reviewsData = this.handelReviews(this.wholeData);
      }
    },
  },
  computed: {
    productSlug() {
      return this.$route.params.slug;
    },
    reviews() {
      let data = [];
      const wholeData = this.handelReviews(this.wholeData);
      this.filters.length > 0
        ? this.filters.map((ele) => {
            data.push(...wholeData.reviews[ele]);
          })
        : ["1", "2", "3", "4", "5"].map((ele) => {
            data.push(...wholeData.reviews[ele]);
          });

      return data;
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
